<template>
    <Results
      :sourceAPI="esgSourceAPI"
    />
</template>

<script>
import Results from './Results';
import resultMixin from '../mixins/results';
export default {
  name: "esgResults",
  mixins: [resultMixin],
  components: {
    Results
  },
};
</script>
<style lang="scss">
.results-wrapper {
  text-align: left;
}
body {
  margin: 0px !important;
}
</style>